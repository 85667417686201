document.addEventListener('DOMContentLoaded', () => {
    toggleMenuMobile();
    moveNavitem();
    checkLayoutBanner();
    changeTrans();
    mainSearch();
    CrMainBanner();
    slidedoitac();
    collapse();
    toggleSidebar();
    toggleSidebar2();
    clickbuttonboloc();
    showBackToTop();
    clickScroll();
    slideCamKet();
    lienhe_active();
    check_breadcrumb();
    displayNoneBut();
});
const displayNoneBut = () => {
    $('.NextPage').parent().css('display', 'none');
    $('.LastPage').parent().css('display', 'none');
    $('.FirstPage').parent().css('display', 'none');
    $('.BackPage').parent().css('display', 'none');
};
const mainSearch = () => {
    $('.icons-search').click(function() {
        $('.search-wrap').css('left', '0');
        $('body').addClass('disabled');
    });
    $('.close-btn').click(function() {
        $('.search-wrap').css('left', '-100%');
        $('body').removeClass('disabled');
    });
};
const changeTrans = () => {
    $('.change-lang1').on('click', function() {
        $('.list-change').toggleClass('active');
    });
};
const toggleMenuMobile = () => {
    $('.toggle-menu').on('click', function() {
        $(this).toggleClass('active');
        $(this).siblings('.nav-mobile').toggleClass('active');
        $('body').toggleClass('disabled');
    });
};
const clickbuttonboloc = () => {
    $('.button-rg-mb').on('click', function() {
        $('.sanpham .box-left').slideToggle();
    });
};
const clickScroll = () => {
    $('.arrow-center').click(function() {
        $('html,body').animate({
                scrollTop: $('.index-1').offset().top - $('header').outerHeight(),
            },
            'fast'
        );
    });
};
$('a').on('click', function(event) {
    if (this.hash !== '') {
        event.preventDefault();
        var hash = this.hash;
        $('html, body').animate({
                scrollTop: $(hash).offset().top -
                    $('header').outerHeight() -
                    $('.gt_nav').outerHeight(),
            },
            0
        );
    } // End if
});
const toggleSidebar = () => {
    $('.toggle-w-1').on('click', function() {
        $('.w-1').toggleClass('active');
    });
};
const toggleSidebar2 = () => {
    $('.toggle-w-2').on('click', function() {
        $('.w-2').toggleClass('active');
    });
};
const slideCamKet = () => {
    var slideCamKet = new Swiper('.ckcl .swiper-container', {
        loop: true,
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
    });
};

function moveNavitem() {
    if ($(window).width() <= 1024) {
        $('.icon > #desktop').appendTo('nav .nav-mobile');
    } else {
        $('.icon > #desktop').appendTo('.main-nav>.nav .icon');
    }
}

//Slide banner
function CrMainBanner() {
    var homeBanner = new Swiper('.slide-home', {
        pagination: {
            el: '.swiper-pagination',
        },
    });
}
// Back to top
function showBackToTop() {
    $('.icon-top').on('click', function() {
        $('html,body').animate({
            scrollTop: 0,
        });
    });
}
//slide2_Home
function slidedoitac() {
    var sldoitac = new Swiper('.sl-doitac', {
        slidesPerView: 6,
        spaceBetween: 30,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 5,
                spaceBetween: 30,
            },
        },
    });
}
//Check banner
const checkLayoutBanner = () => {
    const pagesBanner = $('#page-banner');
    const breadcrumb = $('.global-breadcrumb');
    const heightHeader = $('header').outerHeight();
    const mainBanner = $('#home-banner');
    if (mainBanner.length < 1 && pagesBanner.length < 1) {
        breadcrumb.css('padding-top', heightHeader);
    } else if (pagesBanner.length < 1) {
        mainBanner.css('padding-top', 0);
    } else if (mainBanner.length < 1) {
        pagesBanner.css('padding-top', 0);
    }
};

// Menu Left
function MenuLeft() {
    $('.acc').find('.acc__panel:first').addClass('show');
    $('.acc__title').click(function(j) {
        var dropDown = $(this).closest('.acc__card').find('.acc__panel');
        $(this).closest('.acc').find('.acc__panel').not(dropDown).slideUp();
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
        } else {
            $(this).closest('.acc').find('.acc__title.active').removeClass('active');
            $(this).addClass('active');
        }
        dropDown.stop(false, true).slideToggle();
        j.preventDefault();
    });
}

// Sidebar
$(function() {
    var $ul = $('.sidebar-navigation > ul');
    $ul.find('li .title span').click(function(e) {
        var $li = $(this).parent().parent();

        if ($li.find('ul').length > 0) {
            e.preventDefault();

            if ($li.hasClass('selected')) {
                $li.removeClass('selected').find('li').removeClass('selected');
                $li.find('ul').slideUp(400);
                $li.find('.title span').text('keyboard_arrow_right');
                $li.parent().find('> li .title span').removeClass('green-icon');
            } else {
                if ($li.parents('li.selected').length == 0) {
                    $ul.find('li').removeClass('selected');
                    $ul.find('ul').slideUp(400);
                    $ul.find('li .title span').text('keyboard_arrow_right');
                    $li.parent().find('> li .title span').removeClass('green-icon');
                } else {
                    $li.parent().find('li').removeClass('selected');
                    $li.parent().find('> li ul').slideUp(400);
                    $li.parent().find('> li .title span').text('keyboard_arrow_right');
                    $li.parent().find('> li .title span').removeClass('green-icon');
                }

                $li.addClass('selected');
                $li.find('>ul').slideDown(400);
                $li.find('>.title>span').text('keyboard_arrow_up');
                $li.find('>.title>span').addClass('green-icon');
            }
        }
    });
    $('.sidebar-navigation > ul ul').each(function(i) {
        if ($(this).find('>li>ul').length > 0) {
            var paddingLeft = $(this)
                .parent()
                .parent()
                .find('>li>.title')
                .css('padding-left');
            var pIntPLeft = parseInt(paddingLeft);
            var result = pIntPLeft + 15;
            $(this).find('>li>.title').css('padding-left', result);
        } else {
            var paddingLeft = $(this)
                .parent()
                .parent()
                .find('>li>.title')
                .css('padding-left');
            var pIntPLeft = parseInt(paddingLeft);
            var result = pIntPLeft + 0;
            $(this)
                .find('>li>.title')
                .css('padding-left', result)
                .parent()
                .addClass('selected--last');
        }
    });
    var t = ' li > ul ';

    for (var i = 1; i <= 10; i++) {
        $('.sidebar-navigation > ul > ' + t.repeat(i)).addClass('sub-menu-' + i);
    }

    var activeLi = $('li.selected');

    if (activeLi.length) {
        opener(activeLi);
    }

    function opener(li) {
        var ul = li.closest('ul');

        if (ul.length) {
            li.addClass('selected');
            ul.addClass('open');
            li.find('>.title>span').addClass('mdi-flip-v');

            if (ul.closest('li').length) {
                opener(ul.closest('li'));
            } else {
                return false;
            }
        }
    }
});
//Menu
const collapse = () => {
    $('.button-colapase').on('click', function() {
        var stateActive = $(this).is('.active');
        if (stateActive) {
            $(this).removeClass('active');
            $(this).find('.material-icons').text('expand_more');
        } else {
            $(this).addClass('active').siblings().removeClass('active');
            $(this).find('.material-icons').text('expand_less');
        }
    });
};
// Slide Sản phẩm Chi tiết
var galleryThumbs = new Swiper('.swiper-container.d-1-thumbs', {
    spaceBetween: 10,
    slidesPerView: 5,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
});
var galleryTop = new Swiper('.swiper-container.d-1-top', {
    spaceBetween: 10,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    thumbs: {
        swiper: galleryThumbs,
    },
});

var slide_sanphamdetail = new Swiper('.d_4_slide', {
    slidesPerView: 5,
    spaceBetween: 0,
    loop: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        },
        1440: {
            slidesPerView: 4,
        },
    },
});
//Khuyến mãi chi tiết
var slide_khuyenmaidetail = new Swiper('.k-2-slide', {
    slidesPerView: 3,
    spaceBetween: 30,
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
        },

        992: {
            slidesPerView: 2,
        },
    },
});
//Tin tứcchi tiết
var slide_tintucdetail = new Swiper('.t-2-slide', {
    slidesPerView: 4,
    spaceBetween: 30,
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
        },

        992: {
            slidesPerView: 2,
        },
    },
});
// //Trang liên hệ
// var lienhe_address = document.getElementById('wrapper');
// var box_address = lienhe_address.getElementsByClassName('box');
// for (var i = 0; i < box_address.length; i++) {
//     box_address[i].addEventListener('click', function() {
//         var current = document.getElementsByClassName('active-2');
//         current[0].className = current[0].className.replace(' active-2', '');
//         this.className += ' active-2';
//     });
// }
//Trang giới thiệu
function lienhe_active() {
    if ($('.g-nav').length == 1) {
        var nav_gioithieu = document.getElementById('nav_gioithieu');
        var nav_click = nav_gioithieu.getElementsByClassName('nav-item');
        for (var i = 0; i < nav_click.length; i++) {
            nav_click[i].addEventListener('click', function() {
                var current = document.getElementsByClassName('nav-active');
                current[0].className = current[0].className.replace('nav-active', '');
                this.className += ' nav-active';
            });
        }
    }
}

function check_breadcrumb() {
    const breadcrumb = $('.global-breadcrumb.breadcrum-2');
    if ($('section.tintuc').length == 1) {
        breadcrumb.css('display', 'none');
    }
}
var gioithieu_g3 = new Swiper('.g-3-wrapper', {
    slidesPerView: 'auto',
    spaceBetween: 0,
    effect: 'fade',
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
});
var gioithieu_g6 = new Swiper('.g-6-wrapper', {
    slidesPerView: 2,
    spaceBetween: 30,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});
$('.gt_nav').sticky({
    topSpacing: $('header').outerHeight(),
    zIndex: 999,
});
$('#gioithieu-popup-1').on('click', function() {
    $.fancybox.open(
        '<section class="g-popup" data-fancybox="gallery"> <div class=""> <div class="col-xl-12 mx-auto"> <div class="row align-items-center"> <div class="col-xl-6 p-0"> <div class="wrapper"> <div class="title"> <h2>Tổng quan công ty </h2> </div> <div class="content"> <p>Công ty trách nhiệm hữu hạn thực phẩm Tân Việt Nhật được hình thành dựa trên sự hợp tác chặt chẽ giữa Công ty Okamura Foods – Japan ( Công ty nổi tiếng tại Nhật Bản trong lĩnh vực nuôi trồng, chế biến và phân phối các sản phẩm thủy hải sản tại Nhật Bản và Châu Âu) và Công ty Cổ Phần Thực Phẩm Trung Sơn ( có bề dày trên 15 năm kinh nghiệm trong lĩnh vực nuôi trồng , chế biến và xuất khẩu hải sản cao cấp cho thị trường Nhật Bản ) . Nhờ áp dụng những phương pháp quản lý tiên tiến theo tiêu chuẩn ISO , HACCP , 5S và KAIZEN xuyên suốt từ khâu nhập nguyên liệu đầu vào đến quy trình chế biến và phục vụ, cũng như được hỗ trợ bởi hệ thống sản xuất và cung cấp nguyên vật liệu trên toàn thế giới. Tân Việt Nhật với sứ mạng cam kết mang đến cho người tiêu dùng những sản phẩm ngon an toàn , Có lợi cho sức khỏe với giá cả hợp lý. 4 nhà hàng Tokyo Deli tại các quận.</p> </div> </div> </div> <div class="col-xl-6 p-0"> <div class="img"> <img src="/Data/Sites/2/media/gioi-thieu/g-1.png" alt="" /></div> </div> </div> </div> </div> </section>'
    );
});
$('#gioithieu-popup-2').on('click', function() {
    $.fancybox.open(
        '<section class="g-popup" data-fancybox="gallery"> <div class="col-xl-12 mx-auto"> <div class="row align-items-center"> <div class="col-xl-6 p-0"> <div class="img"> <img src="./assets/gioi-thieu/g-2.png" alt="" /></div> </div> <div class="col-xl-6 p-0"> <div class="wrapper"> <div class="title"> <h2>Lịch sử - Cột mốc</h2> </div> <div class="content"> <p>Nhà hàng TOKYO Deli đầu tiên được mở tại Phú Mỹ Hưng vào năm 2007 và đã dành trọn cảm tình và sự ủng hộ của khách hàng. Hiện nay, Nhà hàng đã trở thành điểm đến thường xuyên của cư dân Phú Mỹ Hưng và Quận 7.</p> </div> <p>Qua 10 năm xây dựng và phát triển cho đến nay Hệ thống Nhà hàng TOKYO Deli đã có tổng cộng 20 chi nhánh, gồm 15 chi nhánh tại TP. Hồ Chí Minh và 5 chi nhánh tại Hà Nội.</p> <p>Với sự tin yêu và ủng hộ của khách hàng, TOKYO Deli tin tưởng đến năm 2020 có thể phát triển được 50 cửa hàng trên toàn quốc.</p> </div> </div> </div> </div> </section>'
    );
});